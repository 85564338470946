.section {
  padding: 60px 16px;
  background-color: $gray0;

  .sectionTitle {
    [data-title] {
      @include fontSize(38px, 120%);

      font-weight: 600;

      @include phone {
        @include fontSize(24px, 130%);
      }
    }

    [data-description] {
      @include fontSize(20px, 150%);

      color: rgba($gray800, 0.64);

      @include phone {
        @include fontSize(16px, 150%);
      }
    }
  }

  .contentWrapper {
    @include flex(row, space-between, flex-start);

    margin: 0 auto;
    max-width: $default-content-width;

    @include tablet {
      @include flex(column, center, center);

      gap: 24px;
    }
  }

  .accordion {
    max-width: 481px;
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;

    p {
      @include fontSize(16px, 24px);

      margin: 0 0 16px;
      color: rgba($gray800, 0.64);
    }

    a {
      @include fontSize(16px, 24px);
      @include flex(row, flex-start, center);

      width: fit-content;
      gap: 8px;
      color: $gray900;
      text-decoration: none;
      font-style: normal;
      border: none;

      svg {
        width: 20px;
        height: 20px;
        transform: rotate(45deg);
      }

      &:hover {
        color: $yellow500;

        svg {
          path {
            fill: $yellow500;
          }
        }
      }
    }
  }

  @include below-tablet {
    padding: 30px 16px;
  }
}
