@import 'styles/utils/colors';
@import 'styles/utils/sizes';
@import 'styles/utils/mixins';

.accordion-item {
  margin-bottom: 4px;
  border-radius: 16px;
  background-color: transparent;
  transition: all 0.3s ease-in-out;
  list-style-type: none;

  &.isClose:hover {
    background-color: $yellow200;
  }

  &.isOpen {
    background-color: $gray50;
  }
}

.accordion-button {
  @include flex(row, space-between, center);

  width: 100%;
  padding: 24px 32px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;

  span {
    @include fontSize(20px, 30px);

    color: $gray900;
    font-family: $default-font;
    font-weight: 600;
    text-align: left;
  }

  .icon {
    transition: all 0.3s ease-in-out;

    path {
      fill: $gray900;
    }

    &.isOpen {
      transform: rotate(-90deg);
    }
  }
}

.accordion-body {
  padding: 0 48px 24px 32px;
}

.accordion-collapse {
  overflow: hidden;
  transition: height 0.3s;
}
