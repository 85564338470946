@import 'styles/utils/mixins';
@import 'styles/utils/colors';
@import 'styles/utils/sizes';
@import 'styles/utils/variables';

.card {
  position: relative;
  padding: 32px;
  width: 100%;
  max-width: 481px;
  background: $gray50;
  border-radius: 16px;
  box-sizing: border-box;

  .card-header {
    @include flex(row, space-between, center);

    position: absolute;
    top: 0;
    left: 0;
    padding: 32px 32px 0;
    width: 100%;
    box-sizing: border-box;
  }

  .action-button {
    @include flex(row, center, center);

    padding: 0;
    width: 24px;
    height: 24px;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;

    path {
      fill: $gray900;
      transition: all ease-in-out 0.3s;
    }

    &:hover {
      path {
        fill: $yellow500;
      }
    }
  }

  .card-body {
    @include flex(column, space-between, center);
  }

  .media {
    margin: 0 0 24px;
    width: 220px;
    height: 220px;
    border-radius: 50%;
    overflow: hidden;
  }

  .name {
    @include fontSize(20px, 150%);

    position: relative;
    margin: 0 0 8px;
    color: $gray900;
    font-weight: 600;
    text-align: center;

    @include below-tablet {
      padding: 0;
      border: none;
    }
  }

  .position {
    @include fontSize(16px, 150%);

    margin: 0 0 24px;
    color: $gray900;
    text-align: center;
  }

  .description {
    @include fontSize(16px, 150%);

    margin: 0 0 24px;
    color: $black-alpha;
    text-align: center;
  }

  .skills-container {
    @include flex(row, center, center, wrap);

    gap: 8px;
  }

  .skill {
    @include fontSize(14px, 150%);

    margin: 0;
    padding: 12px 24px;
    color: $gray900;
    border-radius: 200px;
    background: $gray100;
  }

  .fade-enter {
    opacity: 0;
  }

  .fade-enter-active {
    opacity: 1;
    transition: opacity 300ms;
  }

  .fade-exit {
    opacity: 1;
  }

  .fade-exit-active {
    opacity: 0;
    transition: opacity 300ms;
  }
}
